// src/firebase.js

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

// Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyD2CKIdRyyES-8B50IcM3S8S0CVWWLUT9Q",
    authDomain: "sepa-firehouse.firebaseapp.com",
    projectId: "sepa-firehouse",
    storageBucket: "sepa-firehouse.appspot.com",
    messagingSenderId: "830489454724",
    appId: "1:830489454724:web:ecd5a76f31752bba097ad1",
    measurementId: "G-6QEBJZZFR5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app); // Add this line
export const functions = getFunctions(app);
