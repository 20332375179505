import React, { useState, useEffect, useRef } from "react";
import { collection, doc, query, orderBy, onSnapshot, getDoc } from 'firebase/firestore';
import { db,auth } from "./firebase";
import { ListGroup, Modal, Button, Navbar } from 'react-bootstrap';
import mapboxgl from 'mapbox-gl';


const fetchMapboxToken = async () => {
  try {
    const user = auth.currentUser;
      if (!user) {
          throw new Error('User not authenticated');
      }

      const idToken = await user.getIdToken();

      const response = await fetch('https://us-central1-sepa-firehouse.cloudfunctions.net/getMapboxToken', {
          headers: {
              'Authorization': `Bearer ${idToken}`
          }
      });

      if (!response.ok) {
          throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data.token;
  } catch (error) {
      console.error('Error fetching Mapbox token:', error);
  }
};

function UserList({ agencyAccessCode }) {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [settingsModalShow, setSettingsModalShow] = useState(false);
  const [enableSound, setEnableSound] = useState(false);
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [quadrants, setQuadrants] = useState([]);
  const alertSound = new Audio('/chesterpa.wav');
  const ttsAlert = new Audio('/notif.mp3');
  const [settings, setSettings] = useState({});
  const [ttsAPI, setTtsAPI] = useState('');
  const mapContainerRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
   // Fetch agency settings and notification types
  useEffect(() => {
    const fetchAgencySettings = async () => {
      try {
        const docRef = doc(db, 'agencySettings', agencyAccessCode);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setQuadrants(data.quadrants || []);
          setNotificationTypes(data.notificationTypes || []);
          setTtsAPI(data.ttsAPI || '');

          // Initialize settings for each notification type
          const initialSettings = data.notificationTypes.reduce((acc, type) => {
            acc[type] = {};
            return acc;
          }, {});
          setSettings(initialSettings);
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching agency settings:', error);
      }
    };

    if (agencyAccessCode) {
      fetchAgencySettings();
    }
  }, [agencyAccessCode]);

  useEffect(() => {
    const q = query(
      collection(db, agencyAccessCode),
      orderBy("createDateTime", "desc")
    );
  
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const now = new Date();
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          const userData = change.doc.data();
  
          // Handle different types for createDateTime
          let userDateTime;
          if (userData.createDateTime?.toDate) { // Firestore Timestamp
            userDateTime = userData.createDateTime.toDate();
          } else if (userData.createDateTime instanceof Date) { // JavaScript Date
            userDateTime = userData.createDateTime;
          } else if (typeof userData.createDateTime === 'string') { // String
            userDateTime = new Date(userData.createDateTime);
          }
  
          if (userDateTime) {
            const timeDifference = now - userDateTime;
            if (timeDifference <= 60000) { // 1 minute in milliseconds
              const userType = userData.type;
              const userQuadrant = userData.quadrant;
    
              if (notificationTypes.includes(userType)) {
                const selectedQuadrants = settings[userType] || {};
                const matchedQuadrant = Object.keys(selectedQuadrants).find((q) => q.slice(0, 2) === userQuadrant.slice(0, 2) && selectedQuadrants[q]);
    
                if (matchedQuadrant && enableSound) {
                  alertSound.play();
                  generateSpeech(userData.callType, userData.location);
                  console.error('Matched quadrant:', matchedQuadrant);
                }
              }
            }
          }
        }
      });
      setUsers(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });
  
    return () => unsubscribe();
  }, [agencyAccessCode, settings, notificationTypes, enableSound]);
  

  const generateSpeech = async (callType, location) => {
    try {
      const response = await fetch(ttsAPI, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ callType, location }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to generate speech');
      }
  
      const { audioSource } = await response.json();
      const speechAudio = new Audio(audioSource);
  
      // Sequence of playing sounds
      playSoundSequence(speechAudio);
  
    } catch (error) {
      console.error('Error generating speech:', error);
    }
  };
  
  const playSoundSequence = (speechAudio) => {
    playAudio(alertSound)
      .then(() => playAudio(ttsAlert))
      .then(() => playAudio(speechAudio))
      .then(() => playAudio(ttsAlert))
      .then(() => playAudio(speechAudio));
  };
  
  const playAudio = (audio) => {
    return new Promise((resolve) => {
      audio.onended = resolve;
      audio.play();
    });
  };
  

  const openPopup = (user) => {
    if (user.callType && user.location && user.quadrant) {
      setSelectedUser(user);
    }
    setIsModalOpen(true);
    
  };

  const closePopup = () => {
    setSelectedUser(null);
    setIsModalOpen(false);
  };

  const handleSoundToggle = () => {
    setEnableSound((prevEnableSound) => !prevEnableSound);
    if (!enableSound) {
      // Play a silent sound or very short sound to satisfy browser autoplay policy
     alertSound.play();
    }
  };

  const handleQuadrantToggle = (callType, quadrant) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [callType]: {
        ...prevSettings[callType],
        [quadrant]: !prevSettings[callType]?.[quadrant], // Toggle the value of the quadrant
      },
    }));
  };

  const handleToggleAll = (callType, toggleValue) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [callType]: quadrants.reduce((acc, quadrant) => {
        acc[quadrant] = toggleValue;
        return acc;
      }, {}),
    }));
  };

  useEffect(() => {
    let map;
    fetchMapboxToken().then(token => {
      mapboxgl.accessToken = token;
      // Initialize your Mapbox map here
    });
    if (isModalOpen && selectedUser && selectedUser.latitudeY && selectedUser.longitudeX && mapContainerRef.current) {
      const coordinates = [selectedUser.longitudeX, selectedUser.latitudeY];
  
      const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/josephdambrosio/clgdq3hye009e01mwigchpmp1',
        center: coordinates,
        zoom: 16
      });

      // Create a marker and add it to the map
      new mapboxgl.Marker()
        .setLngLat(coordinates) // Set the marker's coordinates
        .addTo(map); // Add it to the map
      // Delay resizing to ensure the modal is fully rendered
      setTimeout(() => {
        if (map) map.resize();
      }, 300); // Adjust the timeout as needed
      return () => {
        if (map) map.remove();
      };
    }
  }, [isModalOpen, selectedUser]);
  

  

  return (
    <div style={{ width: '100%', maxWidth: '100vw' }}>
    <Navbar bg="light">
  <Navbar.Collapse className="justify-content-end">
    <Button variant={enableSound ? "primary" : "secondary"} onClick={handleSoundToggle} className="mr-2">
      {enableSound ? "Disable Sound Alerts" : "Enable Sound Alerts"}
    </Button>
    <Button onClick={() => setSettingsModalShow(true)} className="btn-spacing">Filter Stations</Button>
  </Navbar.Collapse>
</Navbar>



<Modal show={settingsModalShow} onHide={() => setSettingsModalShow(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Filter Stations</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {notificationTypes.map((callType) => (
      <div key={callType}>
        <h5>{callType}</h5>
        <div className="grid">
          <div className="text-center mt-3">
            <Button
              variant="primary"
              onClick={() => handleToggleAll(callType, true)}
              className="mr-2"
            >
              All On
            </Button>
            <Button
              variant="secondary"
              onClick={() => handleToggleAll(callType, false)}
            >
              All Off
            </Button>
          </div>
          {quadrants.map((quadrant) => (
            <Button
              key={quadrant}
              variant={settings[callType]?.[quadrant] ? "primary" : "secondary"}
              onClick={() => handleQuadrantToggle(callType, quadrant)}
            >
              {quadrant}
            </Button>
          ))}
        </div>
      </div>
    ))}
  </Modal.Body>
</Modal>
      <ListGroup>
        {users.map((user) => {
          // Calculate the combined RGB value
          const combinedRGB = `rgb(${user.backgroundR}, ${user.backgroundG}, ${user.backgroundB})`;
          return (
            <ListGroup.Item
            key={user.id}
            className="d-flex justify-content-between align-items-center"
            onClick={() => openPopup(user)}
            style={{
              backgroundColor: 'black',
              color: combinedRGB,
              fontWeight: 'bold'
            }}
          >
            <div>
              <div style={{ fontWeight: 'bold', fontSize: '2rem' }}>{user.callType}</div>
              <div style={{ color: 'white', fontSize: '1rem' }}>{user.location}</div>
            </div>
            <div style={{ color: 'white', fontWeight: 'bold', fontSize: '1rem' }}>
              <div>{user.quadrant}</div>
            </div>
          </ListGroup.Item>
          
          

          );
        })}
      </ListGroup>


      <Modal show={selectedUser !== null} 
              onHide={closePopup}
              dialogClassName="modal-lg" 
              >
        <Modal.Header closeButton>
          <Modal.Title>Incident Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
  {selectedUser && (
    <div>
      <div ref={mapContainerRef} style={{ height: '400px' }}></div>
      <hr />
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <p><strong>Location:</strong> {selectedUser.location || 'N/A'}</p>
        <p><strong>Dispatch Date/Time:</strong> {selectedUser.dispatchedDateTime || 'N/A'}</p>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p><strong>Call Type:</strong> {selectedUser.callType || 'N/A'}</p>
        <p><strong>Quadrant:</strong> {selectedUser.quadrant || 'N/A'}</p>
      </div>
      <hr />
      {Array.isArray(selectedUser.narratives) && selectedUser.narratives.length > 0 ? (
        selectedUser.narratives
          .sort((a, b) => b.enteredDate.seconds - a.enteredDate.seconds)
          .map((narrativeObj, index) => (
            <div key={index}>{narrativeObj.narrative}</div>
          ))
      ) : (
        <p>No narratives available.</p>
      )}
    </div>
  )}
</Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={closePopup}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UserList;